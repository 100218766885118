// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import inventoryReducer from '../Redux/features/inventory/inventorySlice';
import tabReducer from '../Redux/features/tab/tabSlice';
import configReducer from '../Redux/features/config/configSlice';

export const store = configureStore({
  reducer: {
    inventory: inventoryReducer,
    tab: tabReducer,
    config: configReducer,
  },
});
