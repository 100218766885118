import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { Text } from "../../../Components";
import "../../Trace/detail.css";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setImagePopUp } from "../../../Redux/features/config/configSlice";

export default function Header() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("productId");
  const { history, status, error } = useSelector((state) => state.inventory);

  const handleImageClick = (image) => {
    dispatch(setImagePopUp(image));
  };

  return (
    <header className="header">
      <div className="qrcode-wrap">
        <QRCodeSVG
          value={`https://ceritaproduk.id/?productId=${productId}`}
          size={24}
          className="mr-2"
          onClick={() => {
            handleImageClick({
              image: `https://ceritaproduk.id/?productId=${productId}`,
              type: "qrCode",
            });
          }}
          style={{ cursor: "pointer" }}
        />
        <Text size="regular">{productId}</Text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 15,
        }}
      >
        <img
          src={
            history[history?.length - 1]?.inv?.images[0]?.url ||
            history[history?.length - 1]?.inv?.item?.linkFoto
          }
          alt="SVG Icon"
          style={{
            borderRadius: 10,
            width: 64,
            height: 64,
            objectFit: "contain",
            marginRight: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            handleImageClick({
              image:
                history[history?.length - 1]?.inv?.images[0]?.url ||
                history[history?.length - 1]?.inv?.item?.linkFoto,
              type: "image",
            });
          }}
        />
        <div>
          <Text size="xlarge" bold color={"#006386"}>
            {history[history?.length - 1]?.inv?.item?.nameItem}
          </Text>
          <Text size="regular" color={"#667085"}>
            {history[history?.length - 1]?.inv?.description ||
              history[history?.length - 1]?.inv?.ownerCode}
          </Text>
        </div>
      </div>
    </header>
  );
}
