import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, LoadScript, Marker, Polygon } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "70vh",
  borderRadius: 8,
};

const center = { lat: -6.2, lng: 106.816666 }; // Jakarta

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MyMap = ({ locations }) => {
  const mapRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const hasCalledViewportInfo = useRef(false);

  const getDistance = (lat1, lng1, lat2, lng2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLng = (lng2 - lng1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const getDynamicZoomOut = (distance) => {
    if (distance < 10) return 14;
    if (distance < 30) return 12;
    if (distance < 60) return 11;
    if (distance < 100) return 10;
    if (distance < 200) return 9;
    if (distance < 300) return 8;
    if (distance < 500) return 7;
    if (distance < 700) return 6;
    if (distance < 1000) return 5.5;
    return 5;
  };

  const handleLocationClick = async (lat, lng) => {
    if (!mapRef.current || isAnimating) return;
    setIsAnimating(true);
    hasCalledViewportInfo.current = false;

    const map = mapRef.current;
    const currentCenter = map.getCenter();
    const distance = getDistance(
      currentCenter.lat(),
      currentCenter.lng(),
      lat,
      lng
    );

    const dynamicZoom = getDynamicZoomOut(distance);

    if (distance < 5) {
      map.panTo({ lat, lng });
      setTimeout(() => setIsAnimating(false), 1000);
      return;
    }

    map.setZoom(dynamicZoom);
    setTimeout(() => {
      map.panTo({ lat, lng });
      setTimeout(() => {
        map.setZoom(dynamicZoom + 2);
        setIsAnimating(false);
      }, 1000);
    }, 1000);
  };

  useEffect(() => {
    if (locations.length > 0) {
      handleLocationClick(locations[0].lat, locations[0].lng);
    }
  }, [locations]);

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={(map) => (mapRef.current = map)}
      >
        {locations.map((location) => {
          const polygonPoints =
            location.points && location.points.length >= 3
              ? location.points.map((point) => ({
                  lat: point?.plottingPoint?.coordinates[1],
                  lng: point?.plottingPoint?.coordinates[0],
                }))
              : null;

          return (
            <React.Fragment key={location.id}>
              <Marker
                position={{ lat: location.lat, lng: location.lng }}
                title={location.name}
                onClick={() => handleLocationClick(location.lat, location.lng)}
              />

              {polygonPoints && (
                <Polygon
                  paths={polygonPoints}
                  options={{
                    fillColor: "#4285F4", 
                    fillOpacity: 0.3, 
                    strokeColor: "#1A73E8",
                    strokeOpacity: 0.8, 
                    strokeWeight: 2,
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </GoogleMap>
    </LoadScript>
  );
};

export default MyMap;
