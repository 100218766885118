import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  imagePopUp: null,
};

const configSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setImagePopUp: (state, action) => {
      state.imagePopUp = action.payload;
    },
  },
});

export const { setImagePopUp } = configSlice.actions;
export default configSlice.reducer;
