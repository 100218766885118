import React from "react";
import { useDispatch } from "react-redux";
import user from "../../../Assets/Icons/user.svg";
import { Text } from "../../../Components";
import { setImagePopUp } from "../../../Redux/features/config/configSlice";
import "./card.css";
import { toPrice } from "../../../Helpers";
import { convertArea } from "geolib";

export default function FarmerCard({ card, onPress, inventoryData }) {
  const dispatch = useDispatch();

  const handleImageClick = (image) => {
    dispatch(setImagePopUp({ image: image, type: "image" }));
  };

  return (
    <div className="farmer-card" key={card.id} onClick={onPress}>
      <div
        style={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <div className="card-wrap">
            <div className="icon-container">
              {card?.photo ? (
                <img
                  src={card?.photo}
                  style={{ borderRadius: 99, width: 64, height: 64 }}
                />
              ) : (
                <div className="icon-wrap">
                  <img src={user} alt="SVG Icon" />
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: 20, textAlign: "center" }}>
            <Text size="custom">{card?.partnership?.name || card?.name}</Text>
            <Text color={"#667085"} size="regular">
              {card?.city}
            </Text>
          </div>
        </div>

        <div className="inventory-scroll">
          {inventoryData?.map((inventory, index) =>
            inventory.landName ? (
              <div key={index} className="inventory-container">
                <div className="inventory-card">
                  <div className="inventory-item">
                    <Text
                      size="regular"
                      className="inventory-label"
                      color={"#667085"}
                    >
                      Nama Lahan
                    </Text>
                    <Text size="regular" className="inventory-value" bold>
                      {inventory.landName || "-"}
                    </Text>
                  </div>

                  <div className="inventory-item mt-2">
                    <Text
                      size="regular"
                      className="inventory-label"
                      color={"#667085"}
                    >
                      Komoditas
                    </Text>
                    <Text size="regular" className="inventory-value" bold>
                      {inventory.landplottingitem
                        .map(
                          (item) =>
                            `${item.nameItem}, ${item.amount} ${item.unit}`
                        )
                        .join(" | ") || "-"}
                    </Text>
                  </div>

                  <div className="inventory-item mt-2">
                    <Text
                      size="regular"
                      className="inventory-label"
                      color={"#667085"}
                    >
                      Luas Lahan
                    </Text>
                    <Text size="regular" className="inventory-value" bold>
                      {Number(inventory?.size) > 0
                        ? `${toPrice(
                            convertArea(inventory?.size, "ha"),
                            2
                          )} Ha (${toPrice(
                            inventory?.size / 1000000,
                            4
                          )} Km persegi)`
                        : "-"}
                    </Text>
                  </div>
                </div>
              </div>
            ) : null
          )}
        </div>

        {/* Bagian Deskripsi */}
        <div>
          <div style={{ marginTop: 20 }}>
            <Text size="regular">Deskripsi:</Text>
          </div>
          <div style={{ marginTop: 10 }}>
            <Text
              size="regular"
              color={"#667085"}
              style={{
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {card?.description || "-"}
            </Text>
          </div>
        </div>
        {/* Bagian Foto & Video */}
        <div>
          <div style={{ marginTop: 20 }}>
            <Text size="regular">Foto & Video:</Text>
            <div className="image-list-container-farmer mt-3">
              {card?.multimedia?.map((el, i) => (
                <div
                  key={i}
                  className="image-item-farmer"
                  onClick={() => handleImageClick(el.link)}
                >
                  <img
                    src={el.link}
                    alt={`Product ${i}`}
                    className="image-farmer"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
