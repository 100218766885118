import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useSearchParams,
} from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import Home from './Home';
import { Footer, Header, TabMenu } from './Views/Components';
import { Content, DetailFarmer } from './Views/Trace';
import { store } from './Redux/store';
import { setImagePopUp } from './Redux/features/config/configSlice';
import { QRCodeSVG } from 'qrcode.react';

function ScreenDefault() {
  return (
    <div>
      <Header />
      <TabMenu />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

function ConditionalRoute() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('productId');
  const { imagePopUp } = useSelector((state) => state.config);
  const closeModal = () => {
    dispatch(setImagePopUp(null));
  };

  return (
    <>
      <Routes>
        {productId ? (
          <>
            <Route element={<ScreenDefault />}>
              <Route path="/" element={<Content />} />
              <Route path="/detail-farmer" element={<DetailFarmer />} />
            </Route>
          </>
        ) : (
          <Route path="/" element={<Home />} />
        )}
      </Routes>

      {imagePopUp?.type && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {imagePopUp?.type === 'image' ? (
              <img
                src={imagePopUp?.image}
                alt="Full Size"
                className="full-image"
              />
            ) : (
              <div style={{ cursor: 'pointer' }}>
                <QRCodeSVG value={imagePopUp?.image} size={200} />
              </div>
            )}

            <button
              className="close-btn"
              onClick={closeModal}
              style={{
                marginTop: -40,
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default function HomeV2() {
  return (
    <Provider store={store}>
      <Router>
        <ConditionalRoute />
      </Router>
    </Provider>
  );
}
